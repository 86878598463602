import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class DeviceGuard implements CanLoad
{

    canLoad()
    {
        const href = `${window.location.origin}${window.location.pathname}${window.location.search}`;
        let modifiedUrl;
        const mobileDevice = this.isTabletOrPhone();
        if (href.includes('/web/') && mobileDevice)
        {
            modifiedUrl = href.replace('/web/', '/mobile/');
        }
        else if (href.includes('/mobile/') && !mobileDevice)
        {
            modifiedUrl = href.replace('/mobile/', '/web/');
        }
        if (modifiedUrl)
        {
            window.location.replace(modifiedUrl);
        }
        return true;
    }

    private isTabletOrPhone(): boolean
    {
        const isMobile = (<any>window).isMobile;
        if (isMobile)
        {
            return isMobile.phone === true || isMobile.tablet === true;
        }
        return false;
    }
}
