import { NgModule, ModuleWithProviders } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthServiceProvider } from './auth-service-provider';
import { MoonDeskLibServiceModule } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { OAuthService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule>
  {
    return {
      ngModule: ServicesModule,
      providers: [
        AuthServiceProvider,
        {provide: 'PlatformInfoProvider', useExisting: AuthServiceProvider},
        {provide: 'UserProvider', useExisting: AuthServiceProvider},
        MoonDeskLibServiceModule,
        OAuthService
      ]
    };
  }
}
